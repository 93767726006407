<template>
  <div id="recipes">
    <!-- Прелоадер -->
    <div v-if="isLoading" class="preloader">
      <div class="spinner"></div>
    </div>

    <section v-if="recipes.length" id="last-recipes" class="last-recipes-page">
      <div class="section-title">
        Высокая кухня с заботой о здоровье
      </div>
      <div class="favorites-recipes-list">
        <swiper
            :modules="modules"
            :slides-per-view="2"
            :space-between="20"
            :pagination="pagination2"
            :navigation="navigation2"
            :breakpoints="breakpoints2">
          <swiper-slide v-for="recipe in recipes" :key="recipe.id" class="favorites-recipes-list-item" @click="goToRecipe(recipe.id)">
            <img :src="recipe.image_url" :alt="recipe.title" class="favorites-recipes-list-item__img" loading="lazy" />
            <div class="favorites-recipes-list-item__info">
              <div class="favorites-recipes-list-item__info-title">
                {{ recipe.title }}
              </div>
              <div class="favorites-recipes-list-item__info-description">
                {{ recipe.preview }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="recipes-list-small">
      <div class="section-title">РЕЦЕПТЫ</div>
      <!-- Добавляем теги над списком рецептов -->
      <div class="tags-list">
        <button
            v-for="tag in tagsList"
            :key="tag.id"
            @click="filterByTag(tag.id)"
            :class="{ active: activeTag === tag.id }"
        >
          {{ tag.title }}
        </button>
      </div>

      <!-- Список рецептов -->
      <div class="recipes-list">
        <div
            class="recipes-list-item"
            v-for="(recipe, index) in filteredRecipes"
            :key="index"
            @click="goToRecipe(recipe.id)"
        >
          <div class="recipes-list-item__img">
            <img :src="recipe.image_url" :alt="recipe.title" loading="lazy" />
          </div>
          <div class="recipes-list-item__info">
            <div class="recipes-list-item__info-title">
              {{ recipe.title }}
            </div>
            <div class="recipes-list-item__info-description">
              {{ recipe.preview }}
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import { ref} from 'vue';
import { fetchGoods, fetchFavoriteRecipes, getAllRecipes, getTags } from '@/api';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'RecipesPage',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    // const mainWinner = ref({
    //   name: '',
    //   phone: '',
    // });

    // const selectedWeek = ref(1);
    // const winners = ref([]);
    // const nextDrawDate = ref('10 июля');
    // const availableWeeks = ref({});
    // // const prizes = ref([]);
    // const weeks = ref([
    //   { index: 1, date: '10 июля 2024 г.' },
    //   { index: 2, date: '17 июля 2024 г.' },
    //   { index: 3, date: '24 июля 2024 г.' },
    //   { index: 4, date: '31 июля 2024 г.' },
    //   { index: 5, date: '7 августа 2024 г.' },
    //   { index: 6, date: '14 августа 2024 г.' },
    //   { index: 7, date: 'Главный' },
    // ]);

    const buttonRefs = ref({});

    // const fetchWinnings = async (week) => {
    //   try {
    //     const data = await getWinningsByWeek(week);
    //     if (data.count > 0) {
    //       winners.value = data.results;
    //     } else {
    //       winners.value = [];
    //     }
    //   } catch (error) {
    //     console.error('Error fetching winnings:', error);
    //     winners.value = [];
    //   }
    // };

    // const checkWeeksAvailability = async () => {
    //   const weeksToCheck = [1, 2, 3, 4, 5, 6, 7];

    //   for (const week of weeksToCheck) {
    //     try {
    //       const data = await getWinningsByWeek(week);
    //       availableWeeks.value[week] = data.count > 0;
    //       const specialPrize = data.results.find(item => item.prize.type === 2);
    //       if (specialPrize) {
    //         mainWinner.value.name = specialPrize.winner.name;
    //         mainWinner.value.phone = specialPrize.winner.phone_number;
    //       }
    //     } catch (error) {
    //       availableWeeks.value[week] = false;
    //     }
    //   }

    //   selectWeek(findLastActiveWeek());
    // };

    // const selectWeek = (week) => {
    //   selectedWeek.value = week;
    //   fetchWinnings(week);
    //   updateNextDrawDate(week);
    // };

    // const findLastActiveWeek = () => {
    //   for (let week = 7; week >= 1; week--) {
    //     if (availableWeeks.value[week]) {
    //       return week;
    //     }
    //   }
    //   return 1; // Default to week 1 if no active weeks found
    // };

    // const updateNextDrawDate = (week) => {
    //   const dates = [
    //     '10 июля',
    //     '17 июля',
    //     '24 июля',
    //     '31 июля',
    //     '7 августа',
    //     '14 августа',
    //     'Главный',
    //   ];
    //   nextDrawDate.value = dates[week - 1];
    // };

    // const toggleWinnersAccordion = (week) => {
    //   if (selectedWeek.value === week.index) {
    //     selectedWeek.value = null;
    //   } else {
    //     selectWeek(week.index);

    //     // Прокрутка к началу кнопки
    //     if (window.innerWidth <= 767) { // Условие для мобильных устройств
    //       nextTick(() => {
    //         buttonRefs.value[week.index].scrollIntoView({ behavior: 'smooth', block: 'start' });
    //       });
    //     }
    //   }
    // };

    // onMounted(async () => {
    //   await checkWeeksAvailability();
    // });

    return {
      modules: [ Navigation, Pagination ],
      // Настройки для первого Swiper
      breakpoints1: {
        320: {
          slidesPerView: 1,
        },
      },
      pagination1: { clickable: true },
      navigation1: false,

      // Настройки для второго Swiper
      breakpoints2: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
      },
      pagination2: false,
      navigation2: true,

      // Настройки для третьего Swiper
      breakpoints3: {
        320: {
          slidesPerView: 1,
        }
      },
      pagination3: false,
      navigation3: true,
      // selectedWeek,
      // winners,
      // nextDrawDate,
      // availableWeeks,
      // mainWinner,
      // selectWeek,
      // weeks,
      // toggleWinnersAccordion,
      buttonRefs,
    };
  },
  data() {
    return {
      isLoading: true,
      tagsList: [],
      activeTag: null, // Храним выбранный тег
      filteredRecipes: [], // Отфильтрованные рецепты
      tabs: ['Гарантированные', 'Специальные'],
      selectedTab: 'Гарантированные',
      prizes: [], // Все призы
      guaranteedPrizes: [], // Призы с типом 0 (гарантированные)
      specialPrizes: [], // Призы с типом 3 (специальные)
      goods: [],                    // Массив товаров
      limit: 12,                     // Количество товаров на странице
      offset: 0,                    // Смещение для пагинации
      hasMoreGoods: true,           // Флаг для отображения кнопки "Больше товаров"
      allRecipes: [],
      recipes: [],
      rootUrl: window.location.origin,
      userId: '',
      isMobileNavOpened: false,
      faq: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      },
      loginForm: {
        phone_number: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      mainSettings: {},
      sections: [
        { name: 'Покупай:', text: 'от 1-й упаковки продукции:', component: 'PurchaseSection', scrolled: false },
        { name: 'Регистрируй', text: 'чеки на промо-сайте или в чат-боте', component: 'RegisterSection', scrolled: false},
        { name: 'Выигрывай', text: 'круиз до Углича на двоих или мастер-класс от шеф-повара Григория Мосина и другие ценные призы', component: 'PrizeSection', scrolled: false },
        { name: 'Выкладывай', text: 'кулинарные шедевры в на странице Вконтакте для участия в специальном розыгрыше призов', component: 'ShareSection', scrolled: false }
      ],
      activeSection: 0,
      scrolledSection: -1

    };
  },
  async created() {
    try {
      // Получаем теги
      this.tagsList = await getTags();

      // Загружаем все рецепты
      const recipesData = await getAllRecipes();
      this.allRecipes = recipesData.results;
      this.filteredRecipes = this.allRecipes;
    } catch (error) {
      console.error('Error loading data:', error);
    }
    try {
      // Запросы выполняются параллельно
      const [recipesData] = await Promise.all([
        fetchFavoriteRecipes(),
        // getMainSettings(),
      ]);

      // Присваивание данных
      this.recipes = recipesData.results;
      // this.mainSettings = mainSettings;

      // Вызов других функций
      // this.loadFAQ();
      // this.checkAuthentication();
      // this.fetchTimerData();

    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    } finally {
      this.isLoading = false;
    }
    await this.fetchAllRecipes();
    // await this.fetchPrizes();
    await this.loadGoods();
  },
  mounted() {
    // setAuthHeader();
    // const token = getToken();
    // if (token) {
    //   getUserInfo().then(userInfo => {
    //     if (userInfo) {
    //       this.userId = userInfo.id;
    //       this.user = {
    //         first_name: userInfo.first_name,
    //         last_name_initial: userInfo.last_name.charAt(0),
    //       };
    //       this.questionForm.name = userInfo.first_name;
    //       this.questionForm.email = userInfo.email;
    //     }
    //   }).catch(error => {
    //     console.error('Failed to fetch user info on mount', error);
    //   });
    // }
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    // filteredPrizes() {
    //   return this.prizes.filter(prize => prize.type === 1);
    // },
    // formattedPhoneNumber() {
    //   // Убираем знак '+' и все нецифровые символы
    //   const cleaned = this.phoneNumber.replace(/\D/g, '');

    //   // Проверяем, что номер начинается с '7' и содержит 11 цифр
    //   if (cleaned.length === 11 && cleaned.startsWith('7')) {
    //     return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
    //   }

    //   return ' ';
    // }
  },
  methods: {
    filterByTag(tagId) {
      if (this.activeTag === tagId) {
        // Если активный тег тот же, что был нажат — сбрасываем фильтр
        this.resetFilter();
      } else {
        // Иначе — фильтруем по новому тегу
        this.activeTag = tagId;
        this.filteredRecipes = this.allRecipes.filter(recipe =>
            recipe.tags.some(tag => tag.id === tagId)
        );
      }
    },
    resetFilter() {
      this.activeTag = null;
      this.filteredRecipes = this.allRecipes;
    },
    async fetchAllRecipes() {
      try {
        const data = await getAllRecipes(); // Запрашиваем рецепты
        this.allRecipes = data.results; // Сохраняем рецепты
      } catch (error) {
        console.error('Ошибка при загрузке рецептов:', error);
      }
    },
    selectTab(tab) {
      this.selectedTab = tab; // Изменяем активный таб
    },
    async loadGoods() {
      try {
        const data = await fetchGoods(this.limit, this.offset);
        this.goods = [...this.goods, ...data.results];
        this.offset += this.limit;
        if (data.results.next == null) {
          this.hasMoreGoods = false;
        }
      } catch (error) {
        console.error('Error loading goods:', error);
      }
    },
    async loadMoreGoods() {
      await this.loadGoods();
    },
    goToRecipe(id) {
      // Переход на страницу рецепта
      this.$router.push({ name: 'Recipe', params: { id } });
      window.scrollTo(0, 0); // Прокрутка страницы до верха
    },
    clearError(field) {
      this.errors = { ...this.errors, [field]: null };
    },
    
  },
};
</script>

<style scoped>

</style>
