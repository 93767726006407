<template>

  <div :class="['mobile-nav', { 'is-opened': isMobileNavOpened, 'tg-home-class': isTGHomePage }]">
    <div class="mobile-nav__header">
      <button @click="toggleMobileNav" class="button mobile-button">
        <img src="@/assets/img/icons/close.svg" alt="">
      </button>
    </div>

    <div class="mobile-nav__links">
      <a href="/#recipes" @click="toggleMobileNav" >Рецепты</a>
      <a href="/#buy" @click="toggleMobileNav" >Где купить?</a>
    </div>

    <div class="mobile-nav__footer">
      <div class="social-links">
        <a href="https://t.me/ouglechepole" class="social-links__item" rel="nofollow" target="_blank">
          <img src="@/assets/img/icons/social-tg.svg" alt="">
        </a>
        <a href="https://vk.com/organicforlife" class="social-links__item" rel="nofollow" target="_blank">
          <img src="@/assets/img/icons/social-vk.svg" alt="">
        </a>
        <a href="https://www.youtube.com/channel/UCOOyXZiZyGRukJN3ZO8GPCA" class="social-links__item" rel="nofollow" target="_blank">
          <img src="@/assets/img/icons/social-yt-white.svg" alt="">
        </a>
        <a href="https://rutube.ru/channel/35518097/" class="social-links__item" rel="nofollow" target="_blank">
          <img src="@/assets/img/icons/social-rutube-white.svg" alt="">
        </a>
        <a href="https://dzen.ru/uglicheese" class="social-links__item" rel="nofollow" target="_blank">
          <img src="@/assets/img/icons/social-dzen-white.svg" alt="">
        </a>
      </div>
    </div>
  </div>

  <header :class="[' ', { 'scrolled': isHeaderScrolled, 'tg-home-class': isTGHomePage }]">
    <div class="header">
      <div class="header-logos">
        <a href="/">
          <img src="@/assets/img/ui/logo.svg" alt="">
        </a>
      </div>
      <div class="header-nav">
        <a href="/#recipes">Рецепты</a>
        <a href="/#buy">Где купить?</a>
      </div>
      <div class="header-user">
        <a href="https://ouglechepole.ru/" class="button button-white" target="_blank">Наш основной сайт</a>
      </div>

      <button @click="toggleMobileNav" class="mobile-button button">
        <img src="@/assets/img/icons/mobile-menu.svg" alt="">
      </button>
    </div>
  </header>

  <main :class="{'tg-home-class': isTGHomePage}">
    <router-view></router-view>
  </main>

  <footer :class="{'tg-home-class': isTGHomePage}">
    <div class="footer">
      <div class="footer__left">
        <div class="footer-nav">
          <div class="footer-nav__logo">
            <a href="/">
              <img src="@/assets/img/ui/logo.svg" alt="">
            </a>
          </div>
          <div class="footer-nav__links">
            <a v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank">Политика конфиденциальности</a>
          </div>
        </div>
      </div>
      <div class="footer__right">
        <div class="footer-social">
          <div class="footer-social__links">
            <a href="https://t.me/ouglechepole" target="_blank">
              <img src="@/assets/img/icons/social-tg-dark.svg" alt="">
            </a>
            <a href="https://vk.com/organicforlife" target="_blank">
              <img src="@/assets/img/icons/social-vk-dark.svg" alt="">
            </a>
            <a href="https://www.youtube.com/channel/UCOOyXZiZyGRukJN3ZO8GPCA" target="_blank">
              <img src="@/assets/img/icons/social-yt.svg" alt="">
            </a>
            <a href="https://rutube.ru/channel/35518097/" target="_blank">
              <img src="@/assets/img/icons/social-rutube.svg" alt="">
            </a>
            <a href="https://dzen.ru/uglicheese" target="_blank">
              <img src="@/assets/img/icons/social-dzen.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>


import {getMainSettings} from './api';
import metrikaMixin from "@/mixins/metrikaMixin";

export default {
  name: 'App',
  mixins: [metrikaMixin],
  data() {
    return {
      observer: null,
      isNameFieldDisabled: false,
      isHeaderScrolled: false,
      scrollThreshold: 10,
      rootUrl: window.location.origin,
      userId: '',
      uuid: '',
      isMobileNavOpened: false,
      faq: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      },
      loginForm: {
        phone_number: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      mainSettings: {},
      sections: [
        { name: 'Покупайте товары', component: 'PurchaseSection', scrolled: false },
        { name: 'Регистрируйте чеки', component: 'RegisterSection', scrolled: false},
        { name: 'Участвуйте в викторине', component: 'QuizSection', scrolled: false },
        { name: 'Выигрывайте призы', component: 'PrizeSection', scrolled: false }
      ],
      activeSection: 0,
      scrolledSection: -1

    };
  },
  
  async created() {
    try {
      this.mainSettings = await getMainSettings();
    } catch (error) {
      console.error('Failed to fetch main settings', error);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  computed: {
    isTGHomePage() {
      return this.$route.name === 'TGHomePage' || this.$route.name === 'TGPrivatePage' || this.$route.name === 'TGUploadCheck';
    },
  },

  methods: {
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    handleScroll() {
      this.isHeaderScrolled = window.scrollY > this.scrollThreshold;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/App.scss';
</style>