import axios from 'axios';

// Константа для базового URL API
const API_URL = process.env.VUE_APP_API_URL;
// Функция для получения списка тегов
export const getTags = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/recipes/tags`);
        return response.data.results; // Возвращаем только массив результатов
    } catch (error) {
        console.error('Error fetching tags:', error);
        throw error;
    }
};


// Рецепты
export const fetchFavoriteRecipes = async () => {
    const endpoint = `${API_URL}/api/recipes/?is_favorite=true&limit=2`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching favorite recipes:", error);
        throw error;
    }
};

// Рецепты без favorite
export const getAllRecipes = async () => {
    const endpoint = `${API_URL}/api/recipes/?limit=100`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching non-favorite recipes:", error);
        throw error;
    }
};


// Рецепты без favorite
export const getHomeRecipes = async () => {
    const endpoint = `${API_URL}/api/recipes/?limit=4`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching non-favorite recipes:", error);
        throw error;
    }
};

// Детальная страница рецепта
export const fetchRecipeById = async (id) => {
    const endpoint = `${API_URL}/api/recipes/${id}`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error('Ошибка получения рецепта:', error);
        throw error;
    }
};

// Товары
export const fetchGoods = async (limit, offset) => {
    try {
        const response = await axios.get(`${API_URL}/api/goods/`, {
            params: {
                limit: limit,
                offset: offset
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching goods:', error);
        throw error;
    }
};


// Функция для установки токена в заголовок Authorization
export const setAuthHeader = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenURL = urlParams.get('token');
    let token = '';
    if (tokenURL) {
        token = tokenURL;
    } else {
        token = getToken();
    }
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

// Добавляем интерцептор для обработки ответов
axios.interceptors.response.use(
  response => response,
  error => {
      if (error.response && error.response.status === 401) {
          removeToken();
          delete axios.defaults.headers.common['Authorization'];
      }
      return Promise.reject(error);
  }
);


// Функция для получения списка призов
export const getPrizes = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/quiz/prizes`);
        return response.data.results; // Возвращаем только массив результатов
    } catch (error) {
        console.error('Error fetching prizes:', error);
        throw error;
    }
};

/**
 * Отправка PATCH-запроса для подтверждения гарантированного приза
 * @param {Number} receiptId - ID чека
 * @param {Object} data - Объект с данными для обновления (в данном случае гарантированный приз)
 * @returns {Promise<Object>} - Результат выполнения запроса
 */

export const getToken = () => localStorage.getItem('token');

export const setToken = (token) => localStorage.setItem('token', token);

export const removeToken = () => localStorage.removeItem('token');


export const getMainSettings = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/main/settings`);
        return response.data;
    } catch (error) {
        console.error('Error fetching main settings', error);
        throw error;
    }
};
